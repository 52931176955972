import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import workers from '../images/hero.jpg'
import construction from '../images/construction-worker.jpg'
import plan from '../images/blueprint.jpg'
import execute from '../images/execute.jpg'
import connect from '../images/handshake.jpg'
import pump from '../images/pump.jpg'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
   
    
    <div id="hero" style={{backgroundImage: `url(${workers})`}}></div>
    <div className="main-page">
      <div className="summary">
        <div className="summary-description">
        <h1>Who we are</h1>
        <p>A family owned company based in Columbus, Ohio.
          Ramirez Concrete has been delivering first class, custom concrete services for over 20 years.
        </p>
        </div>
        <img src={construction} alt="construction site"/>

        </div>
        <hr/>
        <div className="service-list">
          <img src={pump} alt="concrete pump"/>
          <div className="service-text">
          <h1>What we do</h1>
          <p>More than your average concrete company, here are some of the services that we offer:</p>
          <ul>
            <li>Stamped Concrete</li>
            <li>Driveways</li>
            <li>Epoxy Flooring</li>
            <li>Concrete Restoration</li>
            <li>All Flatwork <strong>& More</strong></li>
          </ul>
          </div>
        </div>
        <hr/>

        <h1>How we do it</h1>

        <div className="process">
          
          
          <div className="connect" style={{backgroundImage: `linear-gradient(rgba(0,0,0,0.25),rgba(0,0,0,0.25)), url(${connect})`}}>
          <p>Connect</p>
          </div>
          <div className="plan" style={{backgroundImage: `linear-gradient(rgba(0,0,0,0.15),rgba(0,0,0,0.15)), url(${plan})`}}>
          <p>Plan</p>
          </div>
          <div className="execute" style={{backgroundImage: `linear-gradient(rgba(0,0,0,0.15),rgba(0,0,0,0.15)), url(${execute})`}}>
          <p>Execute</p>
          </div>
        </div>
      
    </div>
    
  </Layout>
)
export default IndexPage
